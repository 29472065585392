.top-card-container {
    display: flex;
    justify-content: space-between;
    width: 60%;
    height: 200px;
    margin-bottom: 20px;
    background: rgba(0, 0, 0, 0.2);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    padding: 15px;

    .show-contact {
        position: absolute;
        display: none;
        top: 0;
        right: 0;
        background-color: rgb(250, 240, 230);
        box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.1);
        border: none;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 7px;
        padding: 5px 7px;
        outline: none;
        justify-content: center;

        i {
            font-size: 10px;
            color: rgb(53, 47, 68);
        }
    }

    .container-info {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        gap: 10px;

        .about {
            display: flex;
            width: 60%;
            gap: 30px;

            .image-container {
                height: 100%;

                img {
                    height: 100%;
                    border-radius: 10px;
                }
            }

            .bio {
                display: flex;
                flex-direction: column;
                align-items: start;
                gap: 10%;

                .name {
                    display: flex;
                    gap: 10px;

                    .first-name,
                    .last-name {
                        font-family: "Londrina Outline", sans-serif;
                        font-size: 34px;
                        font-style: italic;
                        font-weight: 800;
                    }

                    .first-name {
                        color: rgb(250, 240, 230);
                    }

                    .last-name {
                        color: rgb(185, 180, 199);
                    }
                }

                .statement {
                    font-size: 16px;
                    background: rgb(250, 240, 230);
                    padding: 5px;
                    border-radius: 5px;
                    color: rgb(53, 47, 68);
                }
            }
        }

        .contact-info {
            width: 40%;
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 10%;

            .info-row {
                height: 40%;
                display: flex;
                align-items: center;
                gap: 5%;

                .icon-holder {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 50px;
                    aspect-ratio: 1;
                    background: rgba(0, 0, 0, 0.2);
                    box-shadow: -1px -1px 2px rgba(0, 0, 0, 0.3);
                    border-radius: 10px;
                    padding: 10px;

                    i {
                        font-size: 22px;
                        color: rgb(185, 180, 199);
                    }
                }

                .email {
                    transform: scale(1);
                    transition: all 0.3s ease-in-out;;

                    a {
                        text-decoration: none;
                        font-size: 18px;
                        color: rgb(185, 180, 199);
                        transition: all 0.3s ease-in-out;;
                    }
                }

                .email:hover {
                    transform: scale(1.05);

                    a {
                        color: rgb(250, 240, 230);
                    }
                }
            }

            .socials {
                width: 100%;
                display: flex;
                gap: 20px;

                a {
                    border: 1px solid rgb(185, 180, 199);
                    border-radius: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-decoration: none;
                    width: 50px;
                    aspect-ratio: 1;
                    transform: scale(1);
                    transition: all 0.3s ease-in-out;;

                    i {
                        font-size: 22px;
                        color: rgb(185, 180, 199);
                    }
                }

                a:hover {
                    border: 1px solid rgb(250, 240, 230);
                    transform: scale(1.1);

                    i {
                        color: rgb(250, 240, 230);
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1350px) {
    .top-card-container {
        width: 80%;
    }
}

@media screen and (max-width: 1050px) {
    .top-card-container {
        width: 90%;
    }
}

@media screen and (max-width: 900px) {
    .top-card-container {
        .container-info {
            width: 100%;
            height: 100%;

            .about {
                width: 60%;

                .bio {
                    .name {

                        .first-name,
                        .last-name {
                            font-size: 28px;
                        }
                    }

                    .statement {
                        font-size: 16px;
                    }
                }
            }

            .contact-info {
                .info-row {
                    .icon-holder {
                        width: 35px;
                        border-radius: 5px;

                        i {
                            font-size: 18px;
                        }
                    }

                    .email {
                        a {
                            font-size: 16px;
                        }
                    }
                }

                .socials {
                    a {
                        width: 35px;
                        aspect-ratio: 1;
                        border-radius: 5px;

                        i {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 820px) {
    .top-card-container {
        height: 150px;
        transition: all 0.5s ease-in-out;

        .show-contact {
            position: absolute;
            display: flex;
            top: 0;
            right: 0;
        }

        .container-info {
            width: 100%;
            height: 100%;
            flex-direction: column;
            gap: 20px;

            .about {
                width: 100%;
                height: 120px;

                .bio {
                    .name {

                        .first-name,
                        .last-name {
                            font-size: 40px;
                        }
                    }

                    .statement {
                        font-size: 20px;
                    }
                }
            }

            .contact-info {
                display: flex;
                opacity: 0;
                width: 100%;
                padding: 10px;
                gap: 10px;
                z-index: -1;
                top: -50;
                transform: translateY(-100px);
                pointer-events: none;
                transition: all 0.5s ease-in-out;

                .info-row {
                    gap: 20px;
                    .icon-holder {
                        width: 40px;
                        border-radius: 5px;

                        i {
                            font-size: 20px;
                        }
                    }

                    .email {
                        a {
                            font-size: 18px;
                        }
                    }
                }

                .socials {
                    justify-content: start;
                    gap: 20px;
                    a {
                        width: 40px;
                        aspect-ratio: 1;
                        border-radius: 5px;

                        i {
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }

    .top-card-container.show {
        height: 300px;

        .container-info {
            .contact-info {
                opacity: 1;
                z-index: 0;
                pointer-events: all;
                transform: translateY(0px);
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .top-card-container {
        height: 120px;

        .container-info {
            width: 100%;
            height: 100%;

            .about {
                height: 90px;

                .bio {
                    .name {

                        .first-name,
                        .last-name {
                            font-size: 34px;
                        }
                    }

                    .statement {
                        font-size: 16px;
                    }
                }
            }

            .contact-info {
                .info-row {
                    .icon-holder {
                        width: 35px;

                        i {
                            font-size: 18px;
                        }
                    }

                    .email {
                        a {
                            font-size: 16px;
                        }
                    }
                }

                .socials {
                    a {
                        width: 35px;

                        i {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }

    .top-card-container.show {
        height: 240px;
    }
}

@media screen and (max-width: 450px) {
    .top-card-container {
        height: 100px;

        .container-info {
            .about {
                height: 70px;

                .bio {
                    .name {

                        .first-name,
                        .last-name {
                            font-size: 24px;
                        }
                    }

                    .statement {
                        font-size: 14px;
                    }
                }
            }

            .contact-info {
                .info-row {
                    .icon-holder {
                        width: 30px;

                        i {
                            font-size: 16px;
                        }
                    }

                    .email {
                        a {
                            font-size: 14px;
                        }
                    }
                }

                .socials {
                    a {
                        width: 30px;

                        i {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }

    .top-card-container.show {
        height: 200px;
    }
}