.main-container {
    .container-info {
        .blogs {
            width: 100%;
            height: 100%;
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
            gap: 20px;
            margin: 20px auto;

            .blog-card {
                cursor: pointer;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 10px;
                text-decoration: none;
                width: 100%;
                background: rgb(53, 47, 68);
                border-radius: 10px;

                .blog-image {
                    position: relative;
                    width: 100%;
                    aspect-ratio: 16 / 9;
                    border-radius: 10px;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        display: block;
                        transform: scale(1);
                        transition: all 0.3s ease-in-out;
                    }
                }

                .content {
                    width: 100%;
                    padding: 0 15px 15px;

                    .blog-title {
                        font-size: 20px;
                        font-weight: bold;
                        color: rgb(250, 240, 230);
                        transition: all 0.3s ease-in-out;
                    }

                    .blog-brief {
                        font-size: 14px;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        line-clamp: 3;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        color: rgb(250, 240, 230);
                    }
                }

                &:hover {
                    .blog-image {
                        img {
                            transform: scale(1.1);
                        }
                    }

                    .content {
                        .blog-title {
                            color: rgb(185, 180, 199);
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 450px) {
    .main-container {
        .container-info {
            .blogs {
                grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
            }
        }
    }
}