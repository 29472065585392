.main-container {
    .container-info {
        .timeline {
            width: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: start;

            &::before {
                content: '';
                position: absolute;
                left: 18px;
                top: 15px;
                bottom: 35px;
                width: 3px;
                background-color: rgba(185, 180, 199, 0.5);
                z-index: 0;
            }

            .timeline-item {
                position: relative;
                display: flex;
                margin-bottom: 30px;
                z-index: 1;
            }

            .dot {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: rgb(53, 47, 68);
                box-sizing: content-box;
                border: 5px solid rgba(185, 180, 199, 0.5);
                position: absolute;
                left: 10px;
                top: 15px;
            }

            .content {
                margin-left: 40px;
                border-radius: 5px;
                color: rgb(250, 240, 230);

                .title {
                    font-size: 18px;
                    font-weight: bold;
                }

                .middle {
                    font-size: 16px;
                }

                .duration {
                    font-size: 14px;
                    color: rgb(185, 180, 199);
                }

                ul {
                    margin: 0;
                    padding: 0 auto;
                }
            }
        }

        .download-container {
            width: 100%;
            display: flex;
            justify-content: flex-end;

            .download-btn {
                background: rgb(53, 47, 68);
                color: rgb(250, 240, 230);
                outline: none;
                border: none;
                border-radius: 10px;
                padding: 10px;
                font-size: 18px;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;
                box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                transition: box-shadow 0.3s ease;
                cursor: pointer;

                &:hover {
                    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
                }
            }
        }
    }
}