.App {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 10px;

    .main-container {
        display: flex;
        justify-content: space-between;
        width: 60%;
        margin-bottom: 100px;
        background: rgba(0, 0, 0, 0.2);
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(10px);
        border-radius: 10px;
        padding: 15px;

        .container-info {
            height: 100%;
            width: 100%;

            .header {
                display: flex;
                gap: 10px;

                .primary-color-header,
                .secondary-color-header {
                    font-family: "Londrina Outline", sans-serif;
                    font-size: 34px;
                    font-weight: 800;
                    letter-spacing: 3px;
                }

                .primary-color-header {
                    color: rgb(250, 240, 230);
                }

                .secondary-color-header {
                    color: rgb(185, 180, 199);
                }
            }

            hr {
                width: 5%;
                margin-left: 0;
                margin-right: auto;
                height: 5px;
                background-color: rgb(185, 180, 199);
                border: none;
                border-radius: 5px;
            }

            .content {
                margin-top: 10px;
                font-size: 18px;
                color: rgb(250, 240, 230);
                line-height: 1.6;
                letter-spacing: 0.5px;
            }
        }
    }

    .playlist-container {
        width: 60%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 100px;

        .container-info {
            height: 100%;
            width: 100%;

            .header {
                display: flex;
                gap: 10px;

                .primary-color-header,
                .secondary-color-header {
                    font-family: "Londrina Outline", sans-serif;
                    font-size: 34px;
                    font-weight: 800;
                    letter-spacing: 3px;
                }

                .primary-color-header {
                    color: rgb(250, 240, 230);
                }

                .secondary-color-header {
                    color: rgb(185, 180, 199);
                }
            }

            hr {
                width: 5%;
                margin-left: 0;
                margin-right: auto;
                height: 5px;
                background-color: rgb(185, 180, 199);
                border: none;
                border-radius: 5px;
            }
        }
        
        .my-playlist {
            width: 100%;
            overflow: hidden;
            border-radius: 10px;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        }
    }
}

@media screen and (max-width: 1350px) {
    .App {
        .main-container {
            width: 80%;
        }

        .playlist-container {
            width: 80%;
        }
    }
}

@media screen and (max-width: 1050px) {
    .App {
        .main-container {
            width: 90%;
        }

        .playlist-container {
            width: 90%;
        }
    }
}

@media screen and (max-width: 820px) {
    .App {
        .main-container, .playlist-container {
            .container-info {
                hr {
                    width: 10%;
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .App {
        .main-container, .playlist-container {
            .container-info {
                hr {
                    width: 15%;
                }
            }
        }
    }
}