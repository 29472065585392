.main-container {
    .container-info {
        .contact-form {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 20px;

            input,
            textarea {
                background: rgb(92, 84, 112);
                color: rgb(250, 240, 230);
                padding: 10px;
                outline: none;
                font-size: 14px;
                letter-spacing: 1px;
                border: none;
                border-radius: 10px;
                box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.2);

                &::placeholder {
                    color: rgba(250, 240, 230, 0.5);
                }
            }

            textarea {
                resize: none;
            }

            button {
                background: rgb(92, 84, 112);
                color: rgb(250, 240, 230);
                padding: 10px;
                outline: none;
                font-size: 14px;
                letter-spacing: 1px;
                border: none;
                border-radius: 10px;
                box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                transition: box-shadow 0.3s ease;
                cursor: pointer;

                &:hover {
                    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
                }

                &:disabled {
                    cursor: not-allowed;
                }
            }

            .status {
                text-align: center;
                font-style: italic;
                font-weight: bold;
            }

            .status.success {
                color: green;
            }

            .status.error {
                color: red;
            }
        }
    }
}