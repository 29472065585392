.main-container {
    .container-info {
        .content {
            margin-top: 10px;
            font-size: 18px;
            color: rgb(250, 240, 230);
            line-height: 1.6;
            letter-spacing: 0.5px;
        }

        .skill-carousel {
            width: 100%;
            height: 250px;
            display: flex;
            gap: 50px;
            overflow-x: scroll;
            padding: 20px;
            user-select: none;

            .skill {
                height: 100%;
                aspect-ratio: 1;
                padding: 20px;
                background-color: rgba(0, 0, 0, 0.2);
                border-radius: 10px;
                filter: grayscale(100%);
                transform: scale(1);
                transition: all 0.3s ease-in-out;

                .skill-icon {
                    width: 100%;
                    height: 100%;
                    -webkit-user-drag: none;
                }
            }

            .skill:hover {
                filter: grayscale(0%);
                transform: scale(1.1);
            }
        }

        .skill-carousel::-webkit-scrollbar {
            height: 10px;
        }

        .skill-carousel::-webkit-scrollbar-thumb {
            background: rgba(185, 180, 199, 0.8);
            border-radius: 10px;
        }

        .skill-carousel::-webkit-scrollbar-track {
            background: rgba(0, 0, 0, 0.1);
            border-radius: 10px;
        }
    }
}