.main-container {
    .container-info {
        .project-type {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 30px;
            margin: 20px 0;
            color: rgb(250, 240, 230);

            .project-type-item {
                font-size: 16px;
                cursor: pointer;
                transform: scale(1);
                transition: all 0.3s ease-in-out;
                position: relative;

                &:hover {
                    color: rgb(185, 180, 199);
                    transform: scale(1.1);
                }

                &::after {
                    content: "";
                    position: absolute;
                    bottom: -2px;
                    left: 0;
                    width: 0;
                    height: 2px;
                    background-color: rgb(185, 180, 199);
                    transition: width 0.4s ease-in-out;
                }

                &.active {
                    color: rgb(185, 180, 199);

                    &::after {
                        width: 100%;
                    }
                }
            }
        }

        .projects {
            width: 100%;
            height: 100%;
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(266.67px, 1fr));
            gap: 20px;
            margin: 0 auto;
        
            .project-card {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 10px;
                text-decoration: none;
                width: 100%;
                height: 200px;
        
                .project-name {
                    font-size: 16px;
                    color: rgb(250, 240, 230);
                    transform: scale(1);
                    cursor: pointer;
                    word-break: keep-all;
                    text-align: center;
                    transition: all 0.3s ease-in-out;
                }
        
                .image-container {
                    position: relative;
                    height: 150px;
                    aspect-ratio: 16 / 9;
                    overflow: hidden;
                    border-radius: 10px;
        
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        display: block;
                        transform: scale(1);
                        transition: all 0.3s ease-in-out;
                    }
        
                    .overlay {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: rgba(0, 0, 0, 0.6);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        opacity: 0;
                        transition: all 0.3s ease-in-out;
        
                        i {
                            color: #fff;
                            font-size: 2rem;
                        }
                    }
                }
        
                &:hover {
                    .project-name {
                        color: rgb(185, 180, 199);
                        transform: scale(1.1);
                    }
        
                    img {
                        transform: scale(1.1);
                    }
        
                    .overlay {
                        opacity: 1;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 450px) {
    .main-container {
        .container-info {
            .project-type {
                gap: 20px;
            }
            
        }
    }
}