body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background: rgb(53, 47, 68);
}

* {
  box-sizing: border-box;
  font-family: "Ubuntu", serif;
}