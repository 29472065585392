.navbar-container {
    position: fixed;
    bottom: 0;
    margin-bottom: 20px;
    z-index: 100;
    display: flex;
    justify-content: space-between;
    background: rgba(0, 0, 0, 0.5);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(20px);
    border-radius: 10px;
    padding: 15px;
    width: 30%;
    min-width: fit-content;

    .navbar {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 10px;

        .nav-btn {
            background-color: transparent;
            border: none;
            outline: none;
            cursor: pointer;
            color: rgb(250, 240, 230);
            padding: 5px 7px;
            font-size: 16px;
            position: relative;
            transition: all 0.5s ease-in-out;

            &::before,
            &::after {
                content: "";
                position: absolute;
                height: 2px;
                background-color: rgb(185, 180, 199);
                width: 0;
                transition: all 0.5s ease-in-out;
            }

            &::before {
                top: 0;
                right: 0;
            }

            &::after {
                bottom: 0;
                left: 0;
            }
        }

        .nav-btn.active {
            color: rgb(185, 180, 199);

            &::before,
            &::after {
                width: 100%;
            }
        }

        .nav-btn:hover {
            transform: scale(1.1);
            color: rgb(185, 180, 199);
        }
    }

}

@media screen and (max-width: 450px) {
    .navbar-container {
        .navbar {
            .nav-btn {
                font-size: 12px;
            }
        }
    }
}